$brand-color: #1cb684;
$brand-color-dark: #0f8560;
$brand-color-light-medium: #96ffbf;
$brand-color-light: #f1fbf8;
$brand-color-secondary: #6d76bd;

$brand-color-highlighting: rgba($brand-color, .07);

$black: #404040;
$grey-light: #707070;
$input-border: #c6c8ca;
$red: #ff6262;
$error: #cf3636;
$grey-lighter: #f7f7f7;
$grey-menu: #d3d3d3;
$main-box-shadow: 0 2px 15px 0px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.05);
$url_fallback_icon_error: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cg%3E%3Cpath d='M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z' fill='%23f44336' fillRule='nonzero'%3E%3C/path%3E%3C/g%3E%3C/svg%3E";
$url_fallback_icon_close: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.271' height='13.271' viewBox='0 0 13.271 13.271'%3E%3Cdefs%3E%3Cstyle%3E .cls-1%7Bfill:none;stroke:%23c3c3c3;stroke-linecap:round;stroke-width:1.5px%7D %3C/style%3E%3C/defs%3E%3Cg id='х-icon' transform='rotate(45 68.172 -279.354)'%3E%3Cpath id='Line_5' d='M0 0v15.768' class='cls-1' data-name='Line 5' transform='translate(226.884 -41.5)'/%3E%3Cpath id='Line_6' d='M0 0v15.768' class='cls-1' data-name='Line 6' transform='rotate(90 134.192 100.576)'/%3E%3C/g%3E%3C/svg%3E%0A";
$background-form: white;

$grid-breakpoints-xs: 0; // X-Small devices (portrait phones, less than 576px)
$grid-breakpoints-sm: 576px; // Small devices (landscape phones, 576px and up)
$grid-breakpoints-md: 768px; // Medium devices (tablets, 768px and up)
$grid-breakpoints-lg: 992px; // Large devices (desktops, 992px and up)
$grid-breakpoints-xl: 1200px; // X-Large devices (large desktops, 1200px and up)
$grid-breakpoints-xxl: 1400px; // XX-Large devices (larger desktops, 1400px and up)
$device-height-sm: 420px; // XX-Large devices (larger desktops, 1400px and up)

 :root {
  --location-input-offset-top: 0;
  --position-btn-view: static;
  --container-height: auto; }
