@import 'variables';

.loading-container {
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255,255,255,.4);
  z-index: 3;

  .spin {
    width: 40px;
    height: 40px;
    margin: auto;
    border: 3px solid rgba(0, 0, 0, .1);
    border-right-color: $brand-color-dark;
    border-top-color: $brand-color-dark;
    border-radius: 50%;
    animation: spin .85s linear 0s infinite; } }
